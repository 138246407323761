@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.triple-column-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		display: flex;
		gap: var(--wp--preset--spacing--60);
		flex-wrap: wrap;

		@media only screen and (max-width: bp.$breakpoint-small) {
			gap: 0;
		}

		.triple-column-block__title {
			flex: 0 1 25%;

			@media only screen and (max-width: bp.$breakpoint-small) {
				flex: 1 1 55%;
			}
		}

		.triple-column-block__content {
			flex: 1 1 200px;
		}
	}

}